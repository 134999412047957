/**
offcanvas: defines css properties for the entire offcanvas component
*/

.offcanvas {
  text-align: left;
  background-color: white;
  min-height: 10vh;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: center;
  opacity: 100%;
}

/**
offcanvas: defines css properties for the offcanvas header
*/
.offcanvas-header {
  background-color: white;
  min-height: 10vh;
  max-width: 50vh;
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/**
offcanvas: defines css properties for the offcanvas body
*/
.offcanvas-Body {
  background-color: white;
  min-height: 10vh;
  max-width: 50vh;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  font-size: calc(10px + 2vmin);
  color: white;
}
